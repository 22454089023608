import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import appServices from "../../Helpers/Services";
import {baseUrl, getUser} from "../../Helpers/Util";

function LiveSession() {
    let { course_id, session_id } = useParams();

    const [course, setCourse] = useState([]);
    const [loading, setLoading] = useState(true);
    const [liveSession, setLiveSession] = useState([]);

    // const [attendance, setAttendance] = useState({});
    const [attendance, setAttendance] = useState({exist: 1});

    function getLiveSessions() {
        setLoading(true);

        appServices.getLiveSessions(session_id, course_id)
            .then(data => {
                setLiveSession(data[0]);

                setLoading(false);
            }).catch(e => console.log(e))
    }

    function getCourse() {
        appServices.getCourses(course_id)
            .then(data => {
                setCourse(data[0]);
            }).catch(e => console.log(e))
    }

    function getAttendance() {
        appServices.getAttendance(session_id)
            .then(data => {
                setAttendance(data);
            }).catch(e => console.log(e))
    }

    function addAttendance() {
        let data = {
            student_id: getUser().student_id,
            live_session_id: Number(session_id)
        }

        appServices.addAttendance(data)
            .then(data => {
                window.location.reload();
            }).catch(e => console.log(e))
    }

    useEffect(() => {
        getLiveSessions();
        getCourse();
        getAttendance();
    }, []);

    function number_formats(x) {
        return parseFloat(x).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    }

    return(
        <div>
            {loading && <div className="col-sm-6 col-md-6 col-lg-6">
                <h4>Loading...</h4>
            </div>}

            {!loading && <div className="row" style={{padding: '20px'}}>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <h4>{liveSession.name}</h4>
                    <h4>{liveSession.start_time}</h4>

                    <br/>
                    {(attendance.length > 0) ?
                        <h4>Session Fee: <span style={{color: '#ef4342'}}>{number_formats(liveSession.amount)} LKR</span>
                        </h4> :
                        <h4>Session Fee: <span style={{color: '#ef4342'}}>One Time Free</span></h4>}
                    <br/>

                    <div>
                        {
                            (attendance.length > 0) ? <button
                                className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2"
                                // onClick={() => enrolCourse(course.id)}
                            >
                                Enroll Now for Unlock Live Session
                            </button> : <button
                                className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2"
                                onClick={() => {
                                    window.open(
                                        liveSession.link,
                                        '_blank'
                                    );
                                    addAttendance();
                                    // setAttendance({exist: 1});
                                }}
                            >
                                Join Now
                            </button>
                        }

                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="card">
                        <img style={{width: '100%'}} src={baseUrl() + course.image_path} alt=""/>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default LiveSession;