import logo from '../../logo.svg';
import { MenuOutline, SearchOutline } from 'react-ionicons'

function Search() {
    return (
        <div className="search">
            {/*<label>*/}
            {/*    <input type="text" placeholder="Search here">*/}
            {/*    </input>*/}
            {/*    <SearchOutline className="ion-icon"/>*/}
            {/*</label>*/}
        </div>
    );
}

export default Search;