import "../../Styles/css/style.css";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import {
    useParams
} from "react-router-dom";
import appService from "../../Helpers/Services";
import {useEffect, useState} from "react";
import {baseUrl, setSelectedCourse, setIsCourseSelected, getUser, getIsLogged} from "../../Helpers/Util";
import { useNavigate } from "react-router-dom";

function SelectedCourse() {
    let { id } = useParams();

    const [loadedCourse, setLoadedCourse] = useState([]);
    const [lecturers, setLecturers] = useState([]);

    let navigate = useNavigate();


    function number_formats(x) {
        return parseFloat(x).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    }

    function loadCourse(id) {
        appService.getCourses(id, 1)
            .then(data => {
                setLoadedCourse({...data[0], fee: number_formats(data[0].fee)});
                setLecturers(data[0].lecturers)
            }).catch(e => console.log(e))
    }

    // function enrolCourseCoupon(id) {
    //     setSelectedCourse(id);
    //     setIsCourseSelected(true);
    //
    //     navigate('/register');
    //     window.location.reload();
    // }

    function enrolCourse(id) {
        setSelectedCourse(id);
        setIsCourseSelected(true);

        // window.location.reload();
        navigate('/login');
    }

    useEffect(() => {
        if(getIsLogged()) {
            navigate('/all-course-levels/'+id);
        }

        loadCourse(id);
    },[])

    return (
        <div>
            <LandingHeader />

            {/*about*/}
            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <img className="img-fluid rounded mb-4 mb-lg-0" src={baseUrl() + loadedCourse.image_path} alt="">
                            </img>
                        </div>
                        <div className="col-lg-7">
                            <div className="text-left mb-4">
                                <h1>{loadedCourse.name}</h1>
                            </div>
                            {/*<h2>{loadedCourse.fee} LKR</h2>*/}

                            <h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: '5px'}}>
                                Lecturer Panel
                            </h5>

                            {
                                lecturers.map(value => {
                                    return(
                                        <p>{value.name}</p>
                                    )
                                })
                            }

                            <button
                                className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2"
                                onClick={() => enrolCourse(loadedCourse.id)}
                            >
                                Enroll Now
                            </button>
                            <br/>
                            {/*<button*/}
                            {/*    className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2"*/}
                            {/*    onClick={() => enrolCourseCoupon(loadedCourse.id)}*/}
                            {/*>*/}
                            {/*    Enroll By Access Token*/}
                            {/*</button>*/}
                        </div>
                    </div>
                    <div className="row align-items-center mt-lg-5">
                        <h4>Course Description</h4>
                        <div
                            className="col-lg-12 text-center mt-2 mt-md-2 mt-lg-2"
                            style={{border: 'solid 1px #bdc3c7', minHeight: '500px'}}
                            dangerouslySetInnerHTML={{__html: loadedCourse.description}}
                        >
                        </div>
                    </div>
                </div>
            </div>

            {/*footer*/}
            <LandingFooter/>

            <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
                <i className="fa fa-angle-double-up">
                </i>
            </a>
        </div>
    );
}

export default SelectedCourse;