import {Link} from "react-router-dom";
import {baseUrl, getUser} from "../../Helpers/Util";
import {useEffect, useState} from "react";
import appServices from "../../Helpers/Services";
import moment from "moment";
import CoursePlaceHolder from "../../Containers/CoursePlaceHolder";

function Courses() {

    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);

    function loadCourses() {
        appServices.getAssignedCourses(getUser().student_id)
            .then(data => {
                setCourses(data);

                setLoading(false);
            }).catch(e => console.log(e))
    }

    useEffect(() => {
        loadCourses();
    }, []);

    return (
        <div>
            {
                !loading && (courses.length <= 0) ?
                    <div className="msgBox">
                        <h4 className="mt-5">You haven't enrolled any course(s)</h4>
                    </div>
                    : null
            }

            <div className="cardBox">

                {
                    loading ? <CoursePlaceHolder /> :
                        (courses.length > 0) ?
                            courses.map(value => {
                                return(
                                    <Link to={"/course-levels/" + value.id} style={{textDecoration: 'none'}}>
                                        <div className="card">
                                            <div>
                                                <div className="card-img">
                                                    <img style={{width: '100%'}} src={baseUrl() + value.image_path} alt=""/>
                                                </div>
                                                <div className="cardName">{value.name}</div>
                                                {/*<div className="cardName">{moment.utc(value.start_date).format('Do MMM YYYY')}</div>*/}
                                                <div className="cardName">Registered {moment.utc(value.created_at).format('Do MMM YYYY')}</div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                            : null
                }


            </div>
        </div>
    );
}

export default Courses;