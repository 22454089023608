import LandingFooter from "../Landing/LandingFooter";
import LandingHeader from "../Landing/LandingHeader";
import loginImg from '../../images/login_Img.png';
import {useState} from "react";
import '../../Styles/css/login.css'
import appServices from "../../Helpers/Services";
import {setUserDetails, setIsLogged} from "../../Helpers/Util";
import { useNavigate } from "react-router-dom";
import {Button, Spinner} from "react-bootstrap";

function Login() {

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    const [formData, setFormData] = useState([]);

    let navigate = useNavigate();

    function login(e) {
        e.preventDefault(e);
        setSubmitDisabled(true);

        appServices.login(formData)
            .then(data => {
                if(data.success == true) {
                    setUserDetails(data.data);
                    setIsLogged(true);

                    window.location.reload();
                    navigate('/all-courses');
                } else {
                    setAlert(true);
                    setAlertMsg(data.message);
                }

                setSubmitDisabled(false);
            }).catch(e => console.log(e))
    }

    return (
        <div>
            <LandingHeader />

            <div className="container-fluid py-5">
                <div className="container ">
                    <div className="text-center mb-5">
                        <h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: '5px'}}>Login</h5>
                    </div>

                    <div className="row justify-content-center ">

                        {
                            alert ?
                                <div className="col-lg-6">
                                    <div className="alert alert-danger">
                                        {alertMsg}
                                    </div>
                                </div>
                                : null
                        }

                        <div className="row justify-content-center form_login">
                            <div className="col-lg-6">
                                <div>
                                    <form className="login" onSubmit={login}>
                                        <div className="control-group">
                                            <input
                                                type="email"
                                                className="form-control border-0 p-4"
                                                id="email"
                                                placeholder="Your Email"
                                                required
                                                onChange={e => setFormData({...formData, email:e.target.value})}
                                            />
                                            <p className="help-block text-danger">
                                            </p>
                                        </div>
                                        <div className="control-group">
                                            <input
                                                type="password"
                                                className="form-control border-0 p-4"
                                                id="password"
                                                placeholder="password"
                                                required
                                                onChange={e => setFormData({...formData, password:e.target.value})}
                                            />
                                            <p className="help-block text-danger">
                                            </p>
                                        </div>
                                        <div style={{textAlign: 'center'}}>
                                            <Button type="submit" variant="primary" className="btn_login" disabled={submitDisabled}>
                                                {submitDisabled && <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />}
                                                {!submitDisabled && 'Submit'}
                                            </Button>
                                        </div>
                                        <div style={{textAlign: 'center'}}>
                                            <a href="/new-register" >Create a new account</a>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            <div className="cols-lg-6">
                                <img src={loginImg} style={{width: '300px',height: 'auto'}}>
                                </img>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <LandingFooter/>
        </div>
    )
}

export default Login;