import logo from "../../images/logo-white-2.png";
import "../../Styles/css/login.css";
import {useEffect} from "react";

function LandingFooter() {
    return (
        <div>
            <div className="container-fluid bg-dark text-white py-5 px-sm-3 px-lg-5" style={{marginTop: '90px'}}>
                <div className="row pt-5">
                    <div className="col-lg-8 col-md-12">
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <h5 className="text-primary text-uppercase mb-4" style={{letterSpacing: '5px'}}>Get In
                                    Touch</h5>
                                <p><i className="fa fa-map-marker-alt mr-2"></i>No:33, Sri soratha Mw, Gangodawila, Nugegoda</p>
                                <p><i className="fa fa-phone-alt mr-2"></i>+94 77 587 2020</p>
                                <p><i className="fa fa-envelope mr-2"></i>info@aihe.lk</p>
                                <div className="d-flex justify-content-start mt-4">
                                    <a className="btn btn-outline-light btn-square mr-2" href="https://www.facebook.com/attrexacademy"><i
                                        className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-outline-light btn-square mr-2" href="https://www.instagram.com/aihe.lk/"><i
                                        className="fab fa-instagram"></i></a>
                                    <a className="btn btn-outline-light btn-square mr-2" href="https://www.youtube.com/channel/UCBs27BdaoqRlw_uulc1niBQ"><i
                                        className="fab fa-youtube"></i></a>
                                    <a className="btn btn-outline-light btn-square" href="https://www.linkedin.com/company/aihelk"><i
                                        className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">

                                <img id="logo"
                                     src={logo}
                                     style={{width: '400px',height: 'auto', borderRadius: '6px', alignItems: 'center', backgroundColor: 'transparent'}}
                                     alt="logo">
                                </img>

                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 mb-4">
                        <h5 className="text-primary text-uppercase mb-4" style={{letterSpacing: '5px'}}>Stay updated</h5>
                        <div className="w-100">
                            <div className="input-group">
                                <input type="text" className="form-control border-light" style={{padding: '30px'}}
                                       placeholder="Your Email Address"/>
                                    <div className="input-group-append">
                                        <button className="btn btn-primary px-4">Subscribe</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingFooter;