import LandingFooter from "../Landing/LandingFooter";
import LandingHeader from "../Landing/LandingHeader";
import loginImg from '../../images/login_Img.png';
import register_img from '../../images/Create_Account_logo.png';

import '../../Styles/css/Register.css'
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import appServices from "../../Helpers/Services";
import {setIsLogged, setUserDetails, getSelectedCourse} from "../../Helpers/Util";
import {Button, Spinner} from "react-bootstrap";

function FreshRegister() {

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [newFormData, setNewFormData] = useState([]);
    const [alert, setAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    let navigate = useNavigate();

    function register(e) {
        e.preventDefault(e);
        setSubmitDisabled(true);

        if (newFormData.password != newFormData.cpassword) {
            setAlert(true);
            setAlertMsg('Password and Confirm Password does not matched');
            setSubmitDisabled(false);
        } else {
            setAlert(false);

            appServices.register(newFormData)
                .then(data => {
                    if(data.success == true) {
                        setUserDetails(data.data);
                        setIsLogged(true);

                        navigate('/all-courses');
                        window.location.reload();
                        // navigate('/pay-confirmation/' + newFormData.course_id);
                    } else {
                        setAlert(true);
                        setAlertMsg(data.message);
                    }

                    setSubmitDisabled(false);
                }).catch(e => console.log(e))
        }
    }

    // useEffect(() => {
    //     setNewFormData({...newFormData, course_id: getSelectedCourse().course_id});
    // }, [])

    return (
        <div>
            <LandingHeader />

            <div className="container-fluid py-5">
                <div className="container ">
                    <div className="text-center mb-5">
                        <h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: '5px'}}>Create your account</h5>
                    </div>
                    <div className="row justify-content-center ">
                    {
                        alert ?
                            <div className="col-lg-6">
                                <div className="alert alert-danger">
                                    {alertMsg}
                                </div>
                            </div>
                            : null
                    }

                        <div className="row justify-content-center form_register ">

                            <div className="col-lg-6">
                                <form
                                    className="Register"
                                    onSubmit={register}
                                >
                                    <div className="control-group">
                                        <input
                                            type="email"
                                            className="form-control border-0 p-4"
                                            placeholder="Email"
                                            required
                                            onChange={e => setNewFormData({...newFormData, email: e.target.value})}
                                        />
                                        <p className="help-block text-danger">
                                        </p>
                                    </div>
                                    <div className="control-group">
                                        <input
                                            type="text"
                                            className="form-control border-0 p-4"
                                            placeholder="Mobile Number"
                                            required
                                            onChange={e => setNewFormData({...newFormData, mobile_number: e.target.value})}
                                        />
                                        <p className="help-block text-danger">
                                        </p>
                                    </div>
                                    <div className="control-group">
                                        <input
                                            type="password"
                                            className="form-control border-0 p-4"
                                            placeholder="Password"
                                            required
                                            onChange={e => setNewFormData({...newFormData, password: e.target.value})}
                                        />
                                        <p className="help-block text-danger">
                                        </p>
                                    </div>
                                    <div className="control-group">
                                        <input
                                            type="password"
                                            className="form-control border-0 p-4"
                                            placeholder="Confirm Password"
                                            required
                                            onChange={e => setNewFormData({...newFormData, cpassword: e.target.value})}
                                        />
                                        <p className="help-block text-danger">
                                        </p>
                                    </div>
                                    <div style={{textAlign: 'center'}}>
                                        <Button type="submit" variant="primary" className="btn_register" disabled={submitDisabled}>
                                            {submitDisabled && <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />}
                                            {!submitDisabled && 'Register and Continue'}
                                        </Button>
                                    </div>

                                </form>
                            </div>

                            <div className="cols-lg-6">
                                <img src={register_img} style={{width: '300px',height: 'auto'}}>
                                </img>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <LandingFooter/>
        </div>
    )
}

export default FreshRegister;