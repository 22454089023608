import {useParams} from "react-router-dom";
import appService from "../../Helpers/Services";
import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import canceled_img from "../../images/payment_cancel.svg";
import success_img from "../../images/payment_success.svg";

function PaymentStatus() {
    let { id, sid } = useParams();

    let navigate = useNavigate();

    const [statusMsg, setStatusMsg] = useState('Please wait...');
    const [status, setStatus] = useState(false);

    function checkStatus() {
        if(id == 0 || sid == 0) {
            setStatus(false);
            setStatusMsg("Payment Failed!");
        } else {
            appService.getPaymentValidation(id, sid)
                .then(data => {
                    if(data.success == true) {
                        setStatusMsg(data.message);
                        setStatus(true);
                    } else {
                        setStatusMsg(data.message);
                        setStatus(false);
                    }
                }).catch(e => console.log(e))
        }
    }

    useEffect(() => {
        checkStatus();
    },[]);

    return (
        <div>
            {/*about*/}
            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="row align-items-center text-center">
                        <div className="col-lg-12">
                            <h1>Payment Status</h1>

                            {
                                (statusMsg !== 'Please wait...' && status == false) ?
                                    <img className="mt-3 mb-3" src={canceled_img} style={{width: '300px', height: 'auto'}}>
                                    </img> : null
                            }

                            {
                                (status == true) ?
                                    <img className="mt-3 mb-3" src={success_img} style={{width: '300px', height: 'auto'}}>
                                    </img> : null
                            }

                            <h4>{statusMsg}</h4>
                            {
                                (status == true) ?
                                    <a href="/courses">
                                    <button className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2">
                                        View Enrolled Courses
                                    </button></a>: null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentStatus;