import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import appServices from "../../Helpers/Services";
import {baseUrl} from "../../Helpers/Util";

function CourseMaterials() {
    let { course_id, level_id } = useParams();

    const levelName = new URLSearchParams(window.location.search).get('levelName');

    const [course, setCourse] = useState([]);
    const [materials, setMaterials] = useState([]);

    const [loading, setLoading] = useState(false);

    function getCourse() {
        setLoading(true);

        appServices.getCourses(course_id)
            .then(data => {
                setCourse(data[0]);

                //get course videos
                getVideos(data[0].id)

                setLoading(false);
            }).catch(e => console.log(e))
    }

    function getVideos() {
        setLoading(true);
        appServices.getMaterials('%', course_id, level_id)
            .then(data => {
                setMaterials(data);

                setLoading(false);
            }).catch(e => console.log(e))
    }

    useEffect(() => {
        getCourse();
    }, []);

    return (
        <div>
            <div className="row" style={{paddingLeft: '20px'}}>
                <div className="col-sm-12">
                    <h4>Course Materials ({course.name + " - " + levelName})</h4>
                </div>
            </div>

            <div className="cardBox">
                {
                    loading ? <h4 className="mt-5">Loading Materials...</h4> :
                        materials.length ?
                            materials.map((value, key) => {
                                return (
                                    <a key={key} href={baseUrl() + value.path} target="_blank" style={{textDecoration: 'none'}}>
                                        <div className="card">

                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <h4>{value.name}</h4>
                                                </div>
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <div className="cardName">Click to Download</div>
                                                </div>
                                            </div>

                                        </div>
                                    </a>
                                )
                            }) : <h4 className="mt-5">Course materials N/A</h4>
                }
            </div>
        </div>
    );
}

export default CourseMaterials;