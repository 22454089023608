import React, {useEffect, useState} from 'react'
import {HomeOutline, BookOutline, PersonAddOutline, VideocamOutline} from 'react-ionicons'
import { Link, NavLink } from "react-router-dom";
import logo from "../../images/logo-white-glow.png"
import {alert} from "ionicons/icons";

const SideNav = ({status}) => {
    useEffect(() => {
        status.current = alertUser
    }, [])

    function alertUser() {
        let navigation = document.querySelector('.navigation');
        navigation.classList.toggle('active');
    }

    // let list = document.querySelectorAll('.navigation li');
    // list.forEach((item) =>
    //     item.addEventListener('click',activelink));
    //
    // function activelink(){
    //     list.forEach((item) =>
    //         item.classList.remove('hovered'));
    //     this.classList.add('hovered');
    // }

    return (
        <div className="navigation">
            <ul>
                <li>
                    <Link to="/">
                        <span className="icon">
                            <img src={logo} style={{width: '300px', height: 'auto'}}/>
                        </span>
                    </Link>
                </li>

                <li>
                    <NavLink
                        className={(navData) => navData.isActive ? "hovered" : "" }
                        to="all-courses"
                        onClick={() => (window.innerWidth <= 991) ? alertUser(): null}
                    >
                            <span className="icon">
                                <BookOutline
                                    color=""
                                    height="49%"
                                    width="49%"
                                />
                            </span>
                        <span className="title">All Courses</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        className={(navData) => navData.isActive ? "hovered" : "" }
                        to="dashboard"
                        onClick={() => (window.innerWidth <= 991) ? alertUser(): null}
                    >
                            <span className="icon">
                                <HomeOutline
                                    color=""
                                    height="49%"
                                    width="49%"
                                />
                            </span>
                        <span className="title"> Dashboard</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        className={(navData) => navData.isActive ? "hovered" : "" }
                        to="courses"
                        onClick={() => (window.innerWidth <= 991) ? alertUser(): null}
                    >
                            <span className="icon">
                                <BookOutline
                                    color=""
                                    height="49%"
                                    width="49%"
                                />
                            </span>
                        <span className="title">Enrolled Courses</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        className={(navData) => navData.isActive ? "hovered" : "" }
                        to="lecturers"
                        onClick={() => (window.innerWidth <= 991) ? alertUser(): null}
                    >
                            <span className="icon">
                                <VideocamOutline
                                    color=""
                                    height="49%"
                                    width="49%"
                                />
                            </span>
                        <span className="title">Meet Lecturers</span>
                    </NavLink>
                </li>
            </ul>

        </div>
    );
}

export default SideNav;