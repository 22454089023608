function CoursePlaceHolder() {
    return(
        <>
            <div className="card">
                <div>
                    <p className="placeholder-glow">
                        <span className="placeholder" style={{width: '100%', height: '150px'}}></span>
                    </p>
                    <div className="cardName">
                        <p className="placeholder-glow">
                            <span className="placeholder" style={{width: '75%'}}></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className="card">
                <div>
                    <p className="placeholder-glow">
                        <span className="placeholder" style={{width: '100%', height: '150px'}}></span>
                    </p>
                    <div className="cardName">
                        <p className="placeholder-glow">
                            <span className="placeholder" style={{width: '75%'}}></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className="card">
                <div>
                    <p className="placeholder-glow">
                        <span className="placeholder" style={{width: '100%', height: '150px'}}></span>
                    </p>
                    <div className="cardName">
                        <p className="placeholder-glow">
                            <span className="placeholder" style={{width: '75%'}}></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className="card">
                <div>
                    <p className="placeholder-glow">
                        <span className="placeholder" style={{width: '100%', height: '150px'}}></span>
                    </p>
                    <div className="cardName">
                        <p className="placeholder-glow">
                            <span className="placeholder" style={{width: '75%'}}></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className="card">
                <div>
                    <p className="placeholder-glow">
                        <span className="placeholder" style={{width: '100%', height: '150px'}}></span>
                    </p>
                    <div className="cardName">
                        <p className="placeholder-glow">
                            <span className="placeholder" style={{width: '75%'}}></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className="card">
                <div>
                    <p className="placeholder-glow">
                        <span className="placeholder" style={{width: '100%', height: '150px'}}></span>
                    </p>
                    <div className="cardName">
                        <p className="placeholder-glow">
                            <span className="placeholder" style={{width: '75%'}}></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className="card">
                <div>
                    <p className="placeholder-glow">
                        <span className="placeholder" style={{width: '100%', height: '150px'}}></span>
                    </p>
                    <div className="cardName">
                        <p className="placeholder-glow">
                            <span className="placeholder" style={{width: '75%'}}></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className="card">
                <div>
                    <p className="placeholder-glow">
                        <span className="placeholder" style={{width: '100%', height: '150px'}}></span>
                    </p>
                    <div className="cardName">
                        <p className="placeholder-glow">
                            <span className="placeholder" style={{width: '75%'}}></span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CoursePlaceHolder;