function LandingCourseHolder() {
    return(
        <>
            <div className="col-lg-4 col-md-6 mb-4">
                <div className="rounded overflow-hidden mb-2">
                    <p className="placeholder-glow">
                        <span className="placeholder" style={{width: '100%', height: '150px'}}></span>
                    </p>
                    <div className="bg-secondary p-4">
                        <div className="d-flex justify-content-between mb-3">
                        </div>
                        <p className="placeholder-glow">
                            <span className="placeholder" style={{width: '75%'}}></span>
                            <span className="placeholder" style={{width: '50%'}}></span>
                            <span className="placeholder" style={{width: '25%'}}></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
                <div className="rounded overflow-hidden mb-2">
                    <p className="placeholder-glow">
                        <span className="placeholder" style={{width: '100%', height: '150px'}}></span>
                    </p>
                    <div className="bg-secondary p-4">
                        <div className="d-flex justify-content-between mb-3">
                        </div>
                        <p className="placeholder-glow">
                            <span className="placeholder" style={{width: '75%'}}></span>
                            <span className="placeholder" style={{width: '50%'}}></span>
                            <span className="placeholder" style={{width: '25%'}}></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
                <div className="rounded overflow-hidden mb-2">
                    <p className="placeholder-glow">
                        <span className="placeholder" style={{width: '100%', height: '150px'}}></span>
                    </p>
                    <div className="bg-secondary p-4">
                        <div className="d-flex justify-content-between mb-3">
                        </div>
                        <p className="placeholder-glow">
                            <span className="placeholder" style={{width: '75%'}}></span>
                            <span className="placeholder" style={{width: '50%'}}></span>
                            <span className="placeholder" style={{width: '25%'}}></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
                <div className="rounded overflow-hidden mb-2">
                    <p className="placeholder-glow">
                        <span className="placeholder" style={{width: '100%', height: '150px'}}></span>
                    </p>
                    <div className="bg-secondary p-4">
                        <div className="d-flex justify-content-between mb-3">
                        </div>
                        <p className="placeholder-glow">
                            <span className="placeholder" style={{width: '75%'}}></span>
                            <span className="placeholder" style={{width: '50%'}}></span>
                            <span className="placeholder" style={{width: '25%'}}></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
                <div className="rounded overflow-hidden mb-2">
                    <p className="placeholder-glow">
                        <span className="placeholder" style={{width: '100%', height: '150px'}}></span>
                    </p>
                    <div className="bg-secondary p-4">
                        <div className="d-flex justify-content-between mb-3">
                        </div>
                        <p className="placeholder-glow">
                            <span className="placeholder" style={{width: '75%'}}></span>
                            <span className="placeholder" style={{width: '50%'}}></span>
                            <span className="placeholder" style={{width: '25%'}}></span>
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
                <div className="rounded overflow-hidden mb-2">
                    <p className="placeholder-glow">
                        <span className="placeholder" style={{width: '100%', height: '150px'}}></span>
                    </p>
                    <div className="bg-secondary p-4">
                        <div className="d-flex justify-content-between mb-3">
                        </div>
                        <p className="placeholder-glow">
                            <span className="placeholder" style={{width: '75%'}}></span>
                            <span className="placeholder" style={{width: '50%'}}></span>
                            <span className="placeholder" style={{width: '25%'}}></span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LandingCourseHolder;