import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import appService from '../Helpers/Services';

function Dashboard() {

    const [loadedData, setLoadedData] = useState('Loading...');

    function loadDashboardData() {
        appService.getDashboardData()
            .then(data => {
                setLoadedData(data.enrolled_courses);
            }).catch(e => console.log(e))
    }

    useEffect(() => {
        loadDashboardData();
    }, [])

    return (
        <div>
            <div className="cardBox">
                <Link to="/courses" style={{textDecoration: 'none'}}>
                <div className="card">
                    <div>
                        <div className="numbers">{(loadedData == 0) ? '00' : loadedData}</div>
                        <div className="cardName">Enrolled Courses</div>
                    </div>
                    <div className="iconBx">
                        <ion-icon name="eye-outline"></ion-icon>
                    </div>
                </div>
                </Link>

                {/*<div className="card">*/}
                {/*    <div>*/}
                {/*        <div className="numbers">$7,504</div>*/}
                {/*        <div className="cardName">Earning</div>*/}
                {/*    </div>*/}
                {/*    <div className="iconBx">*/}
                {/*        <ion-icon name="cash-outline"></ion-icon>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="card">*/}
                {/*    <div>*/}
                {/*        <div className="numbers">1,504</div>*/}
                {/*        <div className="cardName">daily views</div>*/}
                {/*    </div>*/}
                {/*    <div className="iconBx">*/}
                {/*        <ion-icon name="cash-outline"></ion-icon>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="card">*/}
                {/*    <div>*/}
                {/*        <div className="numbers">1,504</div>*/}
                {/*        <div className="cardName">daily views</div>*/}
                {/*    </div>*/}
                {/*    <div className="iconBx">*/}
                {/*        <ion-icon name="eye-outline"></ion-icon>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default Dashboard;