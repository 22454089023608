import { Routes, Route, Redirect, Navigate } from "react-router-dom";

//import components
import Login from "./Views/Login/Login";
import Register from "./Views/Login/Register";
import FreshRegister from "./Views/Login/FreshRegister";
// import Pay from "./Views/Landing/PaymentPage";
// import PayValidate from "./Views/Landing/PaymentValidate";
import Layout from "./Containers/Layout";
import Home from "./Views/Landing/Home";
import SelectedCourses from "./Views/Landing/SelectedCourse";
import {getIsLogged, getIsCourseSelected} from "./Helpers/Util";

import routes from "./routes";

function App() {
  return (
      <div className="App">
          <Routes>
              <Route exact path="*" element={<Home />}/>
              {getIsLogged() && <Route
                  path="/login"
                  element={<Navigate to="/all-courses" />}
              />}
              {!getIsLogged() && <Route
                  path="/dashboard"
                  element={<Navigate to="/login" />}
              />}

              <Route path="/" element={<Home />} />
              <Route path="course/:id" element={<SelectedCourses />} />

              {getIsLogged() && <Route path="/" element={<Layout/>}>
                  {
                      routes.map((route, idx) => {
                          return route.component && (
                              <Route
                                  key={idx}
                                  exact={true}
                                  path={route.path}
                                  element={<route.component/>}
                              />
                          );
                      })
                  }

              </Route>}

              {!getIsLogged() && <Route path="/login" element={<Login/>}/>}
              {!getIsLogged() && getIsCourseSelected() && <Route path="/register" element={<Register/>}/>}
              {!getIsLogged() && <Route path="/new-register" element={<FreshRegister/>}/>}

              {/*{!getIsLogged() && getIsCourseSelected() && <Route path="/pay-confirmation/:id" element={<Pay/>}/>}*/}
              {/*{!getIsLogged() && getIsCourseSelected() && <Route path="/pay-validate/:id/:sid" element={<PayValidate/>}/>}*/}
          </Routes>
      </div>
  );
}

export default App;
