import {useEffect, useRef} from "react";
import videojs from "video.js";
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';


function VideoPlayer(props) {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const { options, onReady } = props;

    useEffect(() => {
        if (!playerRef.current && (options.sources[0].src != "")) {
            const videoElement = videoRef.current;
            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, options, () => {
                onReady && onReady(player);
            });
        } else {
            // you can update player here [update player through props]
            // const player = playerRef.current;
            // player.autoplay(options.autoplay);
            // player.src(options.sources);
        }
    }, [options, videoRef]);

    // Dispose the VideoPlayer.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player>
            <video ref={videoRef} onContextMenu={e => e.preventDefault()} controlsList="nodownload" className="video-js vjs-layout-small vjs-theme-fantasy" />
        </div>
    );
}

export default VideoPlayer;