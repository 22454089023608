
function ContactUs() {

    return(
        <div id='contact'>
            <div className="Contact" id="Contact">
                <div className="container-fluid bg-registration py-5" style={{margin: '90px 0'}}>
                    <div className="container py-5">
                        <div className="row align-items-center">
                            <div className="col-lg-7 mb-5 mb-lg-0">
                                <div className="mb-4">
                                    <h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: '5px'}}>The
                                        best place for distance learning…</h5>
                                    <h1 className="text-white">Choose your favorite courses and learn at home!</h1>
                                </div>
                                <ul className="list-inline text-white m-0">

                                    <h4 style={{color: 'white'}}>
                                        <i className="fa fa-check text-primary mr-3">
                                        </i>
                                         Pick
                                    </h4>
                                    <h4 style={{color: 'white'}}>
                                        <i className="fa fa-check text-primary mr-3">
                                        </i>
                                         Pay
                                    </h4>
                                    <h4 style={{color: 'white'}}>
                                        <i className="fa fa-check text-primary mr-3">
                                        </i>
                                         Learn
                                    </h4>
                                </ul>
                            </div>
                            <div className="col-lg-5">
                                <div className="card border-0">
                                    <div className="card-header bg-light text-center p-4">
                                        <h1 className="m-0">Contact us</h1>
                                    </div>
                                    <div className="card-body rounded-bottom bg-primary p-5">
                                        <form>
                                            <div className="form-group">
                                                <input type="text" className="form-control border-0 p-4"
                                                       placeholder="Your name" required="required"/>
                                            </div>
                                            <div className="form-group">
                                                <textarea className="form-control border-0 py-3 px-4" rows="5"
                                                          id="Inquiry" placeholder="Message"
                                                          required="required"></textarea>
                                            </div>
                                            <div>
                                                <button className="btn btn-dark btn-block border-0 py-3"
                                                        type="submit">Send
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;


