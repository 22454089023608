import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import appServices from "../../Helpers/Services";
import {baseUrl} from "../../Helpers/Util";
import './courseLevels.css';

function CourseLevels() {
    let { course_id } = useParams();

    const [course, setCourse] = useState([]);
    const [levels, setLevels] = useState([]);
    const [liveSessions, setLiveSessions] = useState([]);

    const [loading, setLoading] = useState(true);

    function getCourse() {
        appServices.getCourses(course_id)
            .then(data => {
                setCourse(data[0]);

                //get course videos
                getLevels(data[0].id)
            }).catch(e => console.log(e))
    }

    function getLevels(cid) {
        setLoading(true);

        appServices.getLevels(cid, '%')
            .then(data => {
                setLevels(data);

                getLiveSessions(cid);
            }).catch(e => console.log(e))
    }

    function getLiveSessions(course_id) {
        appServices.getLiveSessions('%', course_id)
            .then(data => {
                setLiveSessions(data);

                setLoading(false);
            }).catch(e => console.log(e))
    }

    useEffect(() => {
        getCourse();
    }, []);

    useEffect(() => {
        console.log(liveSessions)
    }, [liveSessions]);

    return (
        <div>
            {course && <div className="row" style={{padding: '20px'}}>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <h4>{course.name}</h4>
                    <div className="card">
                        <div>
                            <div className="card-img">
                                <img style={{width: '100%'}} src={baseUrl() + course.image_path} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6">
                    <h4>Lesson Access</h4>
                    <div className="cardBox" style={{display: 'contents'}}>
                        {
                            (loading) ? <h4 className="mt-5">Loading Course Levels...</h4> :
                                levels.map((value, key) => {
                                    return (
                                        <>
                                            {
                                                (key === 0) && liveSessions.map(session => {
                                                    if(Number(session.level_id_after) === 0) {
                                                        return(
                                                            <Link key={key}
                                                                  to={"/live-session/" + session.course_id + "/" + session.id + "?sessionName=" + session.name}
                                                                  style={{textDecoration: 'none'}}>
                                                                <div className="card my-card mt-3">
                                                                    <div>
                                                                        <div className="cardName" style={{fontWeight: 600}}>{session.name}</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        )
                                                    }
                                                })
                                            }

                                            <Link key={key}
                                                  to={"/enrolled-course/" + course.id + "/" + value.id + "?levelName=" + value.level_name}
                                                  style={{textDecoration: 'none'}}>
                                                <div className="card mt-3">
                                                    <div>
                                                        <div className="cardName" style={{fontWeight: 600}}>{value.level_name}</div>
                                                    </div>
                                                </div>
                                            </Link>

                                            {
                                                liveSessions.map(session => {
                                                    if(value.id === Number(session.level_id_after)) {
                                                        return(
                                                            <Link key={key}
                                                                  to={"/live-session/" + session.course_id + "/" + session.id + "?sessionName=" + session.name}
                                                                  style={{textDecoration: 'none'}}>
                                                                <div className="card my-card mt-3">
                                                                    <div>
                                                                        <div className="cardName" style={{fontWeight: 600}}>{session.name}</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        )
                                                    }
                                                })
                                            }

                                            {
                                                (levels.length === Number(key+1)) && liveSessions.map(session => {
                                                    if(Number(session.level_id_after) === -1) {
                                                        return(
                                                            <Link key={key}
                                                                  to={"/live-session/" + session.course_id + "/" + session.id + "?sessionName=" + session.name}
                                                                  style={{textDecoration: 'none'}}>
                                                                <div className="card my-card mt-3">
                                                                    <div>
                                                                        <div className="cardName" style={{fontWeight: 600}}>{session.name}</div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        )
                                                    }
                                                })
                                            }
                                        </>
                                    )
                                })
                        }
                    </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 align-items-center mt-lg-5">
                    <h4>Course Description</h4>
                    <div
                        className="col-lg-12 text-center mt-2 mt-md-2 mt-lg-2"
                        style={{border: 'solid 1px #bdc3c7', minHeight: '500px'}}
                        dangerouslySetInnerHTML={{__html: course.description}}
                    >
                    </div>
                </div>

            </div>}
        </div>
    );
}

export default CourseLevels;