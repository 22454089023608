import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import appService from '../Helpers/Services';
import {Button, Spinner} from "react-bootstrap";
import register_img from "../images/Create_Account_logo.png";

function Profile() {

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [studentData, setStudentData] = useState([]);
    const [alert, setAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [success, setSuccess] = useState(false);

    function loadData() {
        appService.getStudentData()
            .then(data => {
                setStudentData(data.data[0]);
            }).catch(e => console.log(e))
    }

    function update(e) {
        e.preventDefault(e);
        setSubmitDisabled(true);

        appService.updateStudent(studentData)
            .then(data => {
                if(data.success) {
                    setSuccess(true);
                    setAlertMsg("Profile details updated");
                } else {
                    setAlert(true);
                    setAlertMsg("All fields are required");
                }

                setSubmitDisabled(false);
            }).catch(e => console.log(e))
    }

    useEffect(() => {
        loadData();
    }, [])

    return (
        <div>

            <div className="container-fluid py-5">
                <div className="container ">
                    <div className="text-center mb-5">
                        <h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: '5px'}}>your account</h5>
                    </div>
                    <div className="justify-content-center">
                        {
                            alert ?
                                <div className="col-lg-12">
                                    <div className="alert alert-danger">
                                        {alertMsg}
                                    </div>
                                </div>
                                : null
                        }

                        {
                            success ?
                                <div className="col-lg-12">
                                    <div className="alert alert-success">
                                        {alertMsg}
                                    </div>
                                </div>
                                : null
                        }

                        <div className="form_register" style={{backgroundColor: '#ECF0F1'}}>

                            <div className="col-lg-12">
                                <form
                                    className="Register"
                                    onSubmit={update}
                                >
                                    <div className="control-group">
                                        <label>First Name</label>
                                        <input
                                            type="text"
                                            className="form-control border-0 p-4"
                                            placeholder="First Name"
                                            value={studentData.first_name}
                                            disabled={submitDisabled}
                                            required
                                            onChange={e => setStudentData({...studentData, first_name: e.target.value})}
                                        />
                                        <p className="help-block text-danger">
                                        </p>
                                    </div>
                                    <div className="control-group">
                                        <label>Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control border-0 p-4"
                                            placeholder="Last Name"
                                            value={studentData.last_name}
                                            disabled={submitDisabled}
                                            required
                                            onChange={e => setStudentData({...studentData, last_name: e.target.value})}
                                        />
                                        <p className="help-block text-danger">
                                        </p>
                                    </div>
                                    <div className="control-group">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            className="form-control border-0 p-4"
                                            placeholder="Email"
                                            value={studentData.email}
                                            disabled={submitDisabled}
                                            onChange={e => setStudentData({...studentData, email: e.target.value})}
                                        />
                                        <p className="help-block text-danger">
                                        </p>
                                    </div>
                                    <div className="control-group">
                                        <label>Mobile Number</label>
                                        <input
                                            type="text"
                                            className="form-control border-0 p-4"
                                            placeholder="Mobile Number"
                                            value={studentData.mobile_number}
                                            disabled={submitDisabled}
                                            required
                                            onChange={e => setStudentData({...studentData, mobile_number: e.target.value})}
                                        />
                                        <p className="help-block text-danger">
                                        </p>
                                    </div>
                                    <div className="control-group">
                                        <label>NIC</label>
                                        <input
                                            type="text"
                                            className="form-control border-0 p-4"
                                            placeholder="NIC"
                                            value={studentData.nic}
                                            disabled={submitDisabled}
                                            required
                                            onChange={e => setStudentData({...studentData, nic: e.target.value})}
                                        />
                                        <p className="help-block text-danger">
                                        </p>
                                    </div>
                                    <div style={{textAlign: 'center'}}>
                                        <Button type="submit" variant="primary" className="btn_register" disabled={submitDisabled}>
                                            {submitDisabled && <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />}
                                            {!submitDisabled && 'Update'}
                                        </Button>
                                    </div>

                                </form>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default Profile;