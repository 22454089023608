import Search from "./Header/Search";
import SideNav from "./Header/SideNav";
import {Link, Outlet} from "react-router-dom";
import {useRef, useState} from "react";
import {alert} from "ionicons/icons";
import '../dashboard-style.css';
import {MenuOutline, SearchOutline} from "react-ionicons";
import logo from "../logo192.png";
import {Popover, OverlayTrigger, Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {getUser} from "../Helpers/Util";

function Layout() {

    const childFunc = useRef(null);
    let navigate = useNavigate();

    function callSideNav() {
        childFunc.current()

        let main = document.querySelector('.main');
        main.classList.toggle('active');
    }

    function logout() {
        localStorage.clear();

        navigate('/');
        window.location.reload();
    }

    const popover = (
        <Popover id="popover-basic" className="text-center" style={(getUser().email == null) ? {width: 'auto', cursor: 'pointer'} : {width: "150px", cursor: 'pointer'}}>
            <Link to="profile" style={{textDecoration: "none"}}>
                <Popover.Header as="h3">
                    Hi,&nbsp;
                    {
                        (getUser().first_name == null) ? "Student" : getUser().first_name
                    }
                </Popover.Header>
            </Link>
            <Popover.Body>
                <center>
                    {/*<span>Profile</span>*/}
                    {/*<hr style={{cursor: 'pointer'}} style={{marginTop: '5px', marginBottom: '5px'}}/>*/}
                    <span style={{cursor: 'pointer'}} onClick={logout}>Logout</span>
                </center>
            </Popover.Body>
        </Popover>
    );

    return (
        <div className="container-fluid" style={{padding: 0}}>
            <SideNav status={childFunc}/>

            <div className="main">

                <div className="topbar">
                    <div className="toggle" onClick={callSideNav}>
                        <MenuOutline
                            className="ion-icon"
                            height="65%"
                            width="65%"
                        />
                    </div>

                    {/*render search component*/}
                    <Search/>

                    <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
                        <div className="user">
                            <img src={logo}/>
                        </div>
                    </OverlayTrigger>
                </div>

                {/*render content of dashboard*/}
                <Outlet />

                {/*<Footer/>*/}
            </div>

        </div>
    );
}

export default Layout;