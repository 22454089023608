import {useEffect, useState} from "react";
import appService from "../../Helpers/Services";
import {baseUrl} from "../../Helpers/Util";

function Teachers() {

    const[teachers, setTeachers] = useState([]);
    const[hide, setHide] = useState(true);

    function loadCourses() {
        appService.getLecturers('%', '1')
            .then(data => {
                setTeachers(data);

                if(data.length)
                    setHide(true);
            }).catch(e => console.log(e))
    }

    function number_formats(x) {
        return x.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    }

    useEffect(data => {
        loadCourses();
    },[])

    return(
        <div id='teacher'>

            {(teachers.length > 0) ?
                <div className="container-fluid py-5">
                <div className="container pb-3">
                    <div className="text-center mb-5">
                        <h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: '5px'}}>Lecturers
                            Panel</h5>
                        <h1>Meet Our Lecturers and Mentors</h1>
                    </div>
                    <div className="row">

                        {
                            teachers.map((value, key) => {
                                return (
                                    <div className="col-md-6 col-lg-3 text-center team mb-4" key={key}>
                                        <div className="team-item rounded overflow-hidden mb-2">
                                            <div className="team-img position-relative">
                                                <img className="img-fluid" src={baseUrl() + value.image_path}
                                                     alt="Lecture image"></img>
                                                {/*<div className="team-social">*/}
                                                {/*    <a className="btn btn-outline-light btn-square mx-1" href="#"><i*/}
                                                {/*        className="fab fa-twitter"></i></a>*/}
                                                {/*    <a className="btn btn-outline-light btn-square mx-1" href="#"><i*/}
                                                {/*        className="fab fa-facebook-f"></i></a>*/}
                                                {/*    <a className="btn btn-outline-light btn-square mx-1" href="#"><i*/}
                                                {/*        className="fab fa-linkedin-in"></i></a>*/}
                                                {/*</div>*/}
                                            </div>
                                            <div className="bg-secondary p-4">
                                                <h5>{value.name}</h5>
                                                <p className="m-0">{value.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </div> : null
            }

        </div>
    )
}

export default Teachers;


