import {useParams} from "react-router-dom";
import {useEffect, useState, useRef} from "react";
import VideoPlayer from "./VideoPlayer";
import 'video.js/dist/video-js.css';
import appServices from "../../Helpers/Services";
import {baseUrl} from "../../Helpers/Util";
// import defaultPreview from "../../images/default_thumb.png";

function Video(props) {
    let { id } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    let lesson = urlParams.get('lesson')

    let [path, setPath] = useState("");
    let [video, setVideo] = useState("");
    let [preview, setPreview] = useState();
    let [course, setCourse] = useState("");
    let [load, setLoad] = useState(false);

    ///////video player data
    const playerRef = useRef(null);

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // you can handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };

    // const changePlayerOptions = () => {
    //   // you can update the player through the Video.js player instance
    //   if (!playerRef.current) {
    //     return;
    //   }
    //   // [update player through instance's api]
    //   playerRef.current.src([{src: baseUrl() + video.path, type: 'video/mp4'}]);
    //   playerRef.current.autoplay(false);
    // };
    ///////video player data

    function getVideo() {
        setLoad(false);

        appServices.getVideos(id)
            .then(async data => {
                setPreview(data[0].preview_path);

                setVideo(data[0]);

                //call get course details
                getCourse(data[0].course_id);
                await getVideoToken(data[0].id);
                setLoad(true);
            }).catch(e => console.log(e))
    }

    function getCourse(id) {
        appServices.getCourses(id)
            .then(data => {
                setCourse(data[0]);
            }).catch(e => console.log(e))
    }

    function getVideoToken(id) {
        appServices.getVideoToken(id)
            .then(data => {
                setPath(baseUrl() + 'video/view/' + data.id + '/' + data.token);
            }).catch(e => console.log(e))
    }

    useEffect(() => {
        getVideo();
    }, []);

    return (
        load && <div>
            <div className="row" style={{paddingLeft: '20px'}}>
                <div className="col-sm-12">
                    <h4>{course.name} - Lesson {lesson}</h4>
                </div>
            </div>

            <div className="row" style={{padding: '20px'}}>
                <div className="col-sm-12">
                    <VideoPlayer options={{
                        // lookup the options in the docs for more options
                        autoplay: true,
                        controls: true,
                        responsive: true,
                        fluid: true,
                        poster: preview,
                        sources: [{
                            src: path,
                            type: 'video/mp4'
                        }]
                    }} onReady={handlePlayerReady} />
                </div>
            </div>
        </div>
    );
}

export default Video;