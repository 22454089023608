import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import appServices from "../../Helpers/Services";
import {baseUrl, getUser} from "../../Helpers/Util";

function LiveMeetings() {
    let { lecturer_id } = useParams();

    const [loading, setLoading] = useState(true);
    const [liveMeeting, setLiveMeeting] = useState([]);
    const [lecturer, setLecturer] = useState([]);

    // const [attendance, setAttendance] = useState({});
    const [attendance, setAttendance] = useState({exist: 1});

    function getLiveMeetings() {
        setLoading(true);

        appServices.getLiveMeetings("%", lecturer_id)
            .then(data => {
                setLiveMeeting(data);

                setLoading(false);
            }).catch(e => console.log(e))
    }

    function getLecturer() {
        setLoading(true);

        appServices.getLecturers(lecturer_id, 1)
            .then(data => {
                setLecturer(data[0]);

                setLoading(false);
            }).catch(e => console.log(e))
    }

    useEffect(() => {
        getLiveMeetings();
        getLecturer();
    }, []);

    function number_formats(x) {
        return parseFloat(x).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    }

    return(
        <div>
            {loading && <div className="col-sm-6 col-md-6 col-lg-6">
                <h4>Loading...</h4>
            </div>}

            {!loading && <div className="row" style={{padding: '20px'}}>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <h4>{lecturer.name}</h4>
                    <div className="card">
                        <img style={{width: '100%'}} src={baseUrl() + lecturer.image_path} alt=""/>
                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6">
                    <h4>Available Meetings</h4>
                    {
                        liveMeeting.map(value => {
                            return(
                                <div className="cardBox" style={{display: 'contents'}}>
                                    <Link
                                        to={"/selected-meeting/" + value.id + "?lecturerName=" + lecturer.name}
                                        style={{textDecoration: 'none'}}>
                                        <div className="card mt-3">
                                            <div>
                                                <div className="cardName" style={{fontWeight: 600}}>{value.name}</div>
                                                <div className="cardName" style={{fontWeight: 600}}>{value.start_date + " | " + value.start_time}</div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
            </div>}
        </div>
    )
}

export default LiveMeetings;