class Api {
  VERSION = 'v1';
  APP_TOKEN = 'abc123';

  BASE_URL = 'https://api.attrexacademy.com';
  // BASE_URL = 'http://127.0.0.1:8000';

  URL = this.BASE_URL + '/api/';

  //login
  LOGIN = this.URL + 'student/login';
  REGISTER = this.URL + 'student/register';
  REGISTER_BY_TOKEN = this.URL + 'student/register/token';

  //student
  GET_STUDENT = this.URL + 'student/get';
  UPDATE_STUDENT = this.URL + 'student/update/';
  CHECK_IS_ENROLLED = this.URL + 'student/is-enrolled/';

  //dashboard
  GET_DASHBOARD_DATA = this.URL + 'student/dashboard/get';

  //courses
  GET_COURSES = this.URL + 'course';
  ASSIGN_FOR_COURSE = this.URL + 'course/assign/student';

  //course levels
  GET_COURSE_LEVELS = this.URL + 'course/levels';

  //videos
  GET_VIDEOS = this.URL + 'videos';
  GET_VIDEO_TOKEN = this.URL + 'video/token/';

  //materials
  GET_MATERIALS = this.URL + 'materials';

  //lecturers
  GET_LECTURER = this.URL + 'lecturer';

  //student
  GET_ASSIGNED_COURSES = this.URL + 'student/assigned-courses';

  //payments
  GET_SESSION = this.URL + 'payment/session';
  PAYMENT_VALIDATE = this.URL + 'payment/validate/';

  //live sessions
  GET_LIVE_SESSIONS = this.URL + 'lives';
  GET_ATTENDANCE = this.URL + 'live/attendance';
  ADD_ATTENDANCE = this.URL + 'live/attendance/add';

  //live meetings
  GET_LIVE_MEETINGS = this.URL + 'meetings';
  GET_MEETING_PAYMENT_SESSION = this.URL + 'meeting/session/get';
  MEETING_PAYMENT_VALIDATE = this.URL + 'meeting/validate/';
}

export default new Api();
