import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import appServices from "../../Helpers/Services";
import {baseUrl, getSelectedCourse, getUser, setIsCourseSelected, setSelectedCourse} from "../../Helpers/Util";
import { useNavigate } from "react-router-dom";
import appService from "../../Helpers/Services";

function AllCourseLevels() {
    let { course_id } = useParams();

    let navigate = useNavigate();

    const [course, setCourse] = useState([]);
    const [levels, setLevels] = useState([]);
    const [lecturers, setLecturers] = useState([]);

    const [loading, setLoading] = useState(true);
    const [isEnrolled, setIsEnrolled] = useState(false);

    function getCourse() {
        appServices.getCourses(course_id)
            .then(data => {
                setCourse(data[0]);

                //get course videos
                getLevels(data[0].id);
                setLecturers(data[0].lecturers);
                setLoading(false);
            }).catch(e => console.log(e))

        appService.checkStudentHasEnrolled(course_id)
            .then(data => {
                setLoading(true);

                if (data.exists) {
                    setIsEnrolled(true);
                } else {
                    setIsEnrolled(false);
                }

                setLoading(false);
            }).catch(e => console.log(e))
    }

    function getLevels(cid) {
        appServices.getLevels(cid, 'free')
            .then(data => {
                setLevels(data);
            }).catch(e => console.log(e))
    }

    function enrolCourse(id) {
        setSelectedCourse(id);
        setIsCourseSelected(true);

        // window.location.reload();
        navigate('/pay-now/'+id);
    }

    function returnEnrolledCourse(id) {
        navigate('/course-levels/'+id);
    }

    useEffect(() => {
        getCourse();
    }, []);

    useEffect(() => {
        console.log(isEnrolled)
    }, [isEnrolled]);

    function number_formats(x) {
        return parseFloat(x).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    }

    return (
        <div>
            {course && <div className="row" style={{padding: '20px'}}>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <h4>{course.name}</h4>
                    <div className="card">
                        <div>
                            <div className="card-img">
                                <img style={{width: '100%'}} src={baseUrl() + course.image_path} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6">
                    <h4>Lesson Access</h4>
                    <div className="cardBox" style={{display: 'contents'}}>
                        {
                            loading && <h4 className="mt-5">Loading Course Levels...</h4>
                        }

                        {
                            (levels.length == 0) ? <h4 className="mt-5">No free or introduction Course Levels</h4> :
                                levels.map((value, key) => {
                                    return (
                                        <Link key={key}
                                              to={"/enrolled-course/" + course.id + "/" + value.id + "?levelName=" + value.level_name}
                                              style={{textDecoration: 'none'}}>
                                            <div className="card mt-3">
                                                <div>
                                                    <div className="cardName" style={{fontWeight: 600}}>{value.level_name}</div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                        }
                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 mt-2">
                    <h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: '5px'}}>
                        Lecturer Panel
                    </h5>

                    {
                        lecturers.map(value => {
                            return(
                                <p>{value.name}</p>
                            )
                        })
                    }
                </div>

                {!loading && <div className="col-sm-6 col-md-6 col-lg-6 mt-2 mb-lg-5">
                    {
                        (course.fee > 0) ? <>
                            <h4>Course Fee: {number_formats(course.fee)} LKR</h4>
                            {
                                isEnrolled ?
                                    <button
                                        className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2"
                                        onClick={() => returnEnrolledCourse(course.id)}
                                    >
                                        View Course
                                    </button> :
                                    <button
                                        className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2"
                                        onClick={() => enrolCourse(course.id)}
                                    >
                                        Enroll Now for Unlock Full Course
                                    </button>

                            }
                        </> : <h4>Course Fee: <span style={{color: '#ef4342'}}>Free</span></h4>
                    }
                </div>}

                <div className="col-sm-12 col-md-12 col-lg-12 align-items-center mt-lg-5">
                    <h4>Course Description</h4>
                    <div
                        className="col-lg-12 text-center mt-2 mt-md-2 mt-lg-2"
                        style={{border: 'solid 1px #bdc3c7', minHeight: '500px'}}
                        dangerouslySetInnerHTML={{__html: course.description}}
                    >
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default AllCourseLevels;