import "../../Styles/css/style.css";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import Courses from "./Courses";
import ContactUs from "./Contact Us";
import Teachers from "./Teachers";

function Home() {

    return (
        <div>
            <LandingHeader />

            {/*carousal*/}
            {/*<div className="container-fluid p-0 pb-5 mb-5">*/}
            {/*    <div id="header-carousel" className="carousel slide carousel-fade" data-ride="carousel">*/}
            {/*        <ol className="carousel-indicators">*/}
            {/*            <li data-target="#header-carousel" data-slide-to="0" className="active"></li>*/}
            {/*            <li data-target="#header-carousel" data-slide-to="1"></li>*/}
            {/*            /!*<li data-target="#header-carousel" data-slide-to="2"></li>*!/*/}
            {/*        </ol>*/}
            {/*        <div className="carousel-inner">*/}
            {/*            <div className="carousel-item active" style={{minHeight: '300px'}}>*/}
            {/*                <img className="position-relative w-100" src="img/carousel-1.jpg"*/}
            {/*                     style={{minHeight: '300px', objectFit: 'cover'}}></img>*/}
            {/*                <div className="carousel-caption d-flex align-items-center justify-content-center">*/}
            {/*                    <div className="p-5" style={{width: '100%', maxWidth: '900px'}}>*/}
            {/*                        <h1 className="display-3 text-white mb-md-4">New way to learn!</h1>*/}
            {/*                        <h5 className="text-white text-uppercase mb-md-3">Learn Anywhere Anytime!</h5>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            /!*<div className="carousel-item" style={{minHeight: '300px'}}>*!/*/}
            {/*            /!*    <img className="position-relative w-100" src="img/carousel-2.jpg"*!/*/}
            {/*            /!*         style={{minHeight: '300px', objectFit: 'cover'}}></img>*!/*/}
            {/*            /!*    <div className="carousel-caption d-flex align-items-center justify-content-center">*!/*/}
            {/*            /!*        <div className="p-5" style={{width: '100%', maxWidth: '900px'}}>*!/*/}
            {/*            /!*            <h1 className="display-3 text-white mb-md-4">New way to learn from home </h1>*!/*/}
            {/*            /!*            <h5 className="text-white text-uppercase mb-md-3">Build your future online at*!/*/}
            {/*            /!*                here…</h5>*!/*/}
            {/*            /!*        </div>*!/*/}
            {/*            /!*    </div>*!/*/}
            {/*            /!*</div>*!/*/}
            {/*            <div className="carousel-item" style={{minHeight: '300px'}}>*/}
            {/*                <img className="position-relative w-100" src="img/carousel-3.jpg"*/}
            {/*                     style={{minHeight: '300px', objectFit: 'cover'}}></img>*/}
            {/*                <div className="carousel-caption d-flex align-items-center justify-content-center">*/}
            {/*                    <div className="p-5" style={{width: '100%', maxWidth: '900px'}}>*/}
            {/*                        <h1 className="display-3 text-white mb-md-4">Recorded Lessons</h1>*/}
            {/*                        <h5 className="text-white text-uppercase mb-md-3">Live support and Mentoring</h5>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*courses*/}
            <Courses/>

            {/*about*/}
            <div className="container-fluid" id="about">
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <img className="img-fluid rounded mb-4 mb-lg-0" src="img/about.jpg" alt=""></img>
                        </div>
                        <div className="col-lg-7">
                            <div className="text-left mb-4">
                                {/*<h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: '5px'}}>About Us</h5>*/}
                                <h1>Empowering Society through Digital Entrepreneurship</h1>
                            </div>
                            <p>We are ATTREX INSTITUTE OF HIGHER EDUCATION (AIHE)! <br/><br/>
                        AIHE brings you this online learning platform under the concept of “Learn at ease”.
                        Through this platform you can pick the course that you like to learn, easily pay and start to learn no matter where you are.
                        AIHE saves your money and time at the same time!
                    </p>
                            <div style={{lineHeight:2}}>

                                <h4><i className="fa fa-check text-primary mr-3"></i> Pick</h4>
                                <h4><i className="fa fa-check text-primary mr-3"></i> Pay</h4>
                                <h4><i className="fa fa-check text-primary mr-3"></i> Learn</h4>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*teachers*/}
            <Teachers/>

            {/*contact us*/}
            <ContactUs/>

            {/*location*/}
            <div className="container-fluid" id="about" style={{paddingLeft: 0, paddingRight: 0}}>
                <div className="text-center mb-5">
                    <h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: '5px'}}>Meet Us</h5>
                    <h1>Our Location</h1>
                </div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.2859120333696!2d79.90504541525117!3d6.856294121105977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25a8810cf4fa1%3A0x654ad65ae7ed476a!2sAihe%20Institute!5e0!3m2!1sen!2slk!4v1649506171365!5m2!1sen!2slk"
                    style={{width: '100%', height: '450px', border: 0}} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>

            {/*footer*/}
            <LandingFooter/>
        </div>
    );
}

export default Home;