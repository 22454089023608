import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import appServices from "../../Helpers/Services";
import {baseUrl, getSelectedCourse, getUser} from "../../Helpers/Util";
import appService from "../../Helpers/Services";

function PaymentPage() {
    let { course_id } = useParams();

    const [course, setCourse] = useState([]);

    function getCourse() {
        appServices.getCourses(course_id)
            .then(data => {
                setCourse(data[0]);
            }).catch(e => console.log(e))
    }

    function checkout() {
        appService.getPaymentSession(course_id, getUser().student_id)
            .then(data => {
                window.Checkout.configure ({
                    session: {
                        id: data.session
                    },
                    interaction: {
                        displayControl: {       // you may change these settings as you prefer
                            billingAddress  : 'HIDE',
                            customerEmail   : 'HIDE',
                            orderSummary    : 'SHOW',
                            shipping        : 'HIDE'
                        }
                    }
                });

                window.Checkout.showPaymentPage();
            }).catch(e => console.log(e))
    }

    useEffect(() => {
        const script = document.createElement('script');

        script.setAttribute('data-error', 'errorCallback');
        script.setAttribute('data-cancel', window.location.origin.toString()+'/payment-status/0/0');
        script.src = "https://cbcmpgs.gateway.mastercard.com/checkout/version/56/checkout.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        getCourse();
    },[])

    function number_formats(x) {
        return parseFloat(x).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    }

    return (
        <div>
            {course &&
            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <div className="card">
                                <div>
                                    <div className="card-img">
                                        <img style={{width: '100%'}} src={baseUrl() + course.image_path} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <div className="text-left mb-4">
                                <h1>{course.name}</h1>
                            </div>
                            <h2>{number_formats(course.fee)} LKR</h2>

                            <button
                                className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2"
                                onClick={() => checkout()}
                            >
                                Pay Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

export default PaymentPage;