import "../../Styles/css/style.css";
import logo from "../../images/Logo-white.png";
import logo_black from "../../images/logo-black.png";

function LandingHeader() {
    return (
        <div>
            {/*Topbar Start*/}
            <div className="container-fluid d-none d-lg-block Topbar">
                <div className="row align-items-center py-4 px-xl-5">
                    <div className="col-lg-3 text-center">
                        <a href="/" className="text-decoration-none">
                            <img id="logo_area"
                                 src={logo}
                                 style={{height: '80px', width: 'auto', backgroundColor: 'transparent'}}
                                 alt="logo"
                            >
                            </img>
                        </a>
                    </div>

                    <div className="col-lg-3 text-center">
                        <div className="d-inline-flex align-items-center">
                            <i className="fa fa-2x fa-map-marker-alt text-primary mr-3"></i>
                            <div className="text-left">
                                <h6 className="font-weight-semi-bold mb-1">Our Office</h6>
                                <small>No:33, Sri soratha Mw, Gangodawila, Nugegoda</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 text-right">
                        <div className="d-inline-flex align-items-center">
                            <i className="fa fa-2x fa-envelope text-primary mr-3"></i>
                            <div className="text-left">
                                <h6 className="font-weight-semi-bold mb-1">Email Us</h6>
                                <small>info@aihe.lk</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 text-right">
                        <div className="d-inline-flex align-items-center">
                            <i className="fa fa-2x fa-phone text-primary mr-3"></i>
                            <div className="text-left">
                                <h6 className="font-weight-semi-bold mb-1">Call Us</h6>
                                <small>+94 77 587 2020</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Topbar End*/}

            {/*Navbar Start*/}
            <div className="container-fluid">
                <div className="row border-top px-xl-5">
                    {/*<div className="col-lg-3 d-none d-lg-block">*/}
                    {/*    <a className="d-flex align-items-center justify-content-between bg-secondary w-100 text-decoration-none" data-toggle="collapse" href="#navbar-vertical" style={{height: '67px', padding: '0 30px'}}>*/}
                    {/*        <h5 className="text-primary m-0"><i className="fa fa-book-open mr-2"></i>Subjects</h5>*/}
                    {/*        <i className="fa fa-angle-down text-primary"></i>*/}
                    {/*    </a>*/}
                    {/*    <nav className="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 border border-top-0 border-bottom-0 bg-light" id="navbar-vertical" style={{width: 'calc(100% - 30px)', zIndex: 9}}>*/}
                    {/*        <div className="navbar-nav w-100">*/}
                    {/*            <a href="#" className="nav-link" data-toggle="dropdown">IOB</a>*/}
                    {/*            <a href="" className="nav-item nav-link">Ez Express English </a>*/}
                    {/*            <a href="" className="nav-item nav-link">Ez Express Spoken</a>*/}
                    {/*            <a href="" className="nav-item nav-link">Returnex</a>*/}
                    {/*            <a href="" className="nav-item nav-link">E-Marketing</a>*/}
                    {/*        </div>*/}
                    {/*    </nav>*/}
                    {/*</div>*/}
                    <div className="col-lg-12">
                        <nav className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">

                            <img
                                id="log"
                                className="navbar-toggler"
                                src={logo_black}
                                style={{
                                    border: 'none',
                                    height: '80px',
                                    width: 'auto',
                                    backgroundColor: 'transparent'
                                }}
                                alt="logo"
                            >
                            </img>

                            {/*<button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">*/}
                            {/*    <span className="navbar-toggler-icon"></span>*/}
                            {/*</button>*/}

                            <a href="/login">
                                <button type="button" style={{color: 'white'}} className="btn btn-primary py-2 px-4 ml-auto navbar-toggler">
                                    Login
                                </button>
                            </a>

                            <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                                <div className="navbar-nav ml-auto">
                                    <a className="nav-item nav-link active">&#160;</a>
                                    {/*<a href="#about" className="nav-item nav-link">About</a>*/}
                                    {/*<a href="#courses" className="nav-item nav-link">Courses</a>*/}
                                    {/*<a href="#teacher" className="nav-item nav-link">Teachers</a>*/}
                                    {/*<a href="#contact" className="nav-item nav-link">Contact</a>*/}
                                </div>
                                <a className="btn btn-primary py-2 px-4 ml-auto d-none d-lg-block" href="/login">Login</a>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            {/*Navbar End*/}

        </div>
    );
}

export default LandingHeader;