import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import appServices from "../../Helpers/Services";
import {baseUrl, getSelectedCourse, getUser} from "../../Helpers/Util";
import appService from "../../Helpers/Services";

function MeetingPaymentPage() {
    let { id } = useParams();

    const lecturer_name = new URLSearchParams(window.location.search).get('lecturerName');
    const [meeting, setMeeting] = useState([]);
    const [loading, setLoading] = useState(true);

    function getMeeting() {
        appServices.getLiveMeetings(id)
            .then(data => {
                setMeeting(data[0]);

                setLoading(false);
            }).catch(e => console.log(e))
    }

    function checkout() {
        appService.getMeetingPaymentSession(id, getUser().student_id)
            .then(data => {
                window.Checkout.configure ({
                    session: {
                        id: data.session
                    },
                    interaction: {
                        displayControl: {       // you may change these settings as you prefer
                            billingAddress  : 'HIDE',
                            customerEmail   : 'HIDE',
                            orderSummary    : 'SHOW',
                            shipping        : 'HIDE'
                        }
                    }
                });

                window.Checkout.showPaymentPage();
            }).catch(e => console.log(e));
    }

    useEffect(() => {
        const script = document.createElement('script');

        script.setAttribute('data-error', 'errorCallback');
        script.setAttribute('data-cancel', window.location.origin.toString()+'/meeting/payment-status/0/0');
        script.src = "https://cbcmpgs.gateway.mastercard.com/checkout/version/56/checkout.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        getMeeting();
    },[])

    function number_formats(x) {
        return parseFloat(x).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    }

    return (
        <div>
            {meeting &&
            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="row align-items-center">

                        {
                            (loading == true) ?
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <h4>Loading...</h4>
                                </div>
                                : <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="text-left mb-4">
                                        <h1>{meeting.name + " - " + lecturer_name}</h1>
                                    </div>
                                    <div className="text-left mb-4">
                                        {meeting.start_date + " | " + meeting.start_time}
                                    </div>
                                    <h2>
                                        {number_formats(meeting.amount)} LKR
                                    </h2>

                                    <button
                                        className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2"
                                        onClick={() => checkout()}
                                    >
                                        Pay Now
                                    </button>
                                </div>
                        }

                    </div>
                </div>
            </div>
            }
        </div>
    );
}

export default MeetingPaymentPage;