import jwt_decode from 'jwt-decode';
import api from './Api';

export const baseUrl = () => {
  return api.BASE_URL + '/';
}

export const setUserDetails = (data) => {
  let userDetails = {
    student_id: data.id,
    email: data.email,
    created: data.created_at,
    first_name: data.first_name,
    last_name: data.last_name,
  }
  localStorage.setItem('aihe-portal.user-details', JSON.stringify(userDetails));
}

export const getUser = () => {
  return JSON.parse(localStorage.getItem('aihe-portal.user-details'));
}

//session token authentication
export const setToken = (token) => {
  localStorage.setItem('aihe-portal.session-token', token);
}

export const setIsLogged = (status) => {
  localStorage.setItem('aihe-portal.is-logged', status);
}

export const getIsLogged = () => {
  return localStorage.getItem('aihe-portal.is-logged') || false;
}

export const checkLogin = () => {
  var jwt = window.localStorage.getItem('aihe-portal.session-token');
  if (jwt && getIsLogged()) {
    var decoded = jwt_decode(jwt);
    return Date.now() / 1000 <= decoded.exp;
  } else {
    return false;
  }
}

export const logout = () => {
  if(getIsLogged()){
    localStorage.clear();
    window.location.reload();
  }
}
//session token authentication

//course
export const setSelectedCourse = (id) => {
  let cDetails = {
    course_id: id,
  }
  localStorage.setItem('aihe-portal.selected-course-details', JSON.stringify(cDetails));
}

export const getSelectedCourse = () => {
  return JSON.parse(localStorage.getItem('aihe-portal.selected-course-details'));
}

export const setIsCourseSelected = (status) => {
  localStorage.setItem('aihe-portal.is-selected-course', status);
}

export const getIsCourseSelected = () => {
  return localStorage.getItem('aihe-portal.is-selected-course') || false;
}
