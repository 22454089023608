import axios from 'axios';
import api from './Api';
import {getUser} from "./Util";

class Services {
    //login
    login(data) {
        axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
        axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

        return new Promise((resolve, reject) => {
            axios
                .post(api.LOGIN, data)
                .then(result => {
                    resolve(result.data)
                })
                .catch(error => reject(error));
        });
    }

    register(data) {
        axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
        axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

        return new Promise((resolve, reject) => {
            axios
                .post(api.REGISTER, data)
                .then(result => {
                    resolve(result.data)
                })
                .catch(error => reject(error));
        });
    }

    registerByToken(data) {
        axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
        axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

        return new Promise((resolve, reject) => {
            axios
                .post(api.REGISTER_BY_TOKEN, data)
                .then(result => {
                    resolve(result.data)
                })
                .catch(error => reject(error));
        });
    }

    //student
    getStudentData() {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

            axios.get(api.GET_STUDENT, {params: {id:getUser().student_id}})
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    updateStudent(data) {
        axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
        axios.defaults.headers.common['session-token'] = window.localStorage.getItem('sp-admin.session-token');

        return new Promise((resolve, reject) => {
            axios
                .put(api.UPDATE_STUDENT + getUser().student_id, data)
                .then(result => {
                    resolve(result.data)
                })
                .catch(error => reject(error));
        });
    }

    checkStudentHasEnrolled(course_id = null) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

            axios.get(api.CHECK_IS_ENROLLED + getUser().student_id + '/' + course_id)
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    //dashboard
    getDashboardData() {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

            axios.get(api.GET_DASHBOARD_DATA, {params: {student_id:getUser().student_id}})
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    //courses
    getCourses(id='%', status = '%', random = null) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

            axios.get(api.GET_COURSES, {params: {id:id, status:status, random:random}})
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    assignForCourse(data) {
        axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
        axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

        return new Promise((resolve, reject) => {
            axios
                .post(api.ASSIGN_FOR_COURSE, data)
                .then(result => {
                    resolve(result.data)
                })
                .catch(error => reject(error));
        });
    }

    //get levels
    getLevels(course_id= '%', level_type = '%') {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

            axios.get(api.GET_COURSE_LEVELS, {params: {course_id:course_id, level_type: level_type}})
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    //get videos
    getVideos(id= '%' ,course_id='%', level_id = '%') {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

            axios.get(api.GET_VIDEOS, {params: {id:id, course_id:course_id, level_id:level_id}})
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    getVideoToken(id= '0') {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

            axios.get(api.GET_VIDEO_TOKEN + id)
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    //materials
    getMaterials(id= '%' ,course_id='%', level_id = '%') {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

            axios.get(api.GET_MATERIALS, {params: {id:id, course_id:course_id, level_id:level_id}})
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    //lecturers
    getLecturers(id='%', status = '%') {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

            axios.get(api.GET_LECTURER, {params: {id:id, status:status}})
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    //student
    getAssignedCourses(id='%') {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

            axios.get(api.GET_ASSIGNED_COURSES, {params: {student_id:id}})
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    //payments
    getPaymentSession(course_id= 0, student_id = 0) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

            axios.get(api.GET_SESSION, {params: {course_id:course_id, student_id:student_id}})
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    getPaymentValidation(id= '', sid = '') {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

            axios.get(api.PAYMENT_VALIDATE + id + '/' + sid)
                .then(result => {
                    resolve(result.data)
                })
                .catch(error => reject(error));
        });
    }

    //live sessions
    getLiveSessions(id = '%', course_id = '%') {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('sp-admin.session-token');

            axios.get(api.GET_LIVE_SESSIONS, {params: {id:id, course_id:course_id}})
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    getAttendance(live_session_id = '') {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('sp-admin.session-token');

            axios.get(api.GET_ATTENDANCE, {params: {student_id:getUser().student_id, live_session_id:live_session_id}})
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    addAttendance(data) {
        axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
        axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

        return new Promise((resolve, reject) => {
            axios
                .post(api.ADD_ATTENDANCE, data)
                .then(result => {
                    resolve(result.data)
                })
                .catch(error => reject(error));
        });
    }

    //live meetings
    getLiveMeetings(id = '%', lecturer_id = '%') {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('sp-admin.session-token');

            axios.get(api.GET_LIVE_MEETINGS, {params: {id:id, lecturer_id:lecturer_id}})
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    getMeetingPaymentSession(live_meeting_id= 0, student_id = 0) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

            axios.get(api.GET_MEETING_PAYMENT_SESSION, {params: {live_meeting_id:live_meeting_id, student_id:student_id}})
                .then(result => {
                    if (result.data.success) {
                        resolve(result.data.data)
                    } else {
                        reject(result)
                    }
                })
                .catch(error => reject(error));
        });
    }

    getMeetingPaymentValidation(id= '', sid = '') {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['app-token'] = api.APP_TOKEN;
            axios.defaults.headers.common['session-token'] = window.localStorage.getItem('aihe-portal.session-token');

            axios.get(api.MEETING_PAYMENT_VALIDATE + id + '/' + sid)
                .then(result => {
                    resolve(result.data)
                })
                .catch(error => reject(error));
        });
    }
}

export default new Services();

