import React from 'react';

import Dashboard from "./Views/Dashboard";
import Profile from "./Views/Profile";
import Courses from "./Views/Courses/Courses";
import AllCourses from "./Views/Courses/AllCourses";
import EnrolledCourse from "./Views/Courses/EnrolledCourse";
import CourseLevels from "./Views/Courses/CourseLevels";
import AllCourseLevels from "./Views/Courses/AllCourseLevels";
import CourseMaterials from "./Views/Courses/CourseMaterials";
import PayNow from "./Views/Courses/PaymentPage";
import PaymentStatus from "./Views/Courses/PaymentStatus";
import Video from "./Views/Courses/Video";
import LiveSession from "./Views/Courses/LiveSession";
import Lecturers from "./Views/Lecturer/Leturers";
import LiveMeetings from "./Views/Lecturer/LiveMeetings";
import MeetingPaymentPage from "./Views/Lecturer/MeetingPaymentPage";
import MeetingPaymentStatus from "./Views/Lecturer/MeetingPaymentStatus";

// const Dashboard = React.lazy(() => import('./Views/Dashboard'));
// const Courses = React.lazy(() => import('./Views/Courses/Courses'));

const routes = [
    { path: 'dashboard', component: Dashboard },
    { path: 'profile', component: Profile },

    { path: 'courses', component: Courses },
    { path: 'all-courses', component: AllCourses },
    { path: 'course-levels/:course_id', component: CourseLevels },
    { path: 'all-course-levels/:course_id', component: AllCourseLevels },
    { path: 'enrolled-course/:course_id/:level_id', component: EnrolledCourse },
    { path: 'course-materials/:course_id/:level_id', component: CourseMaterials },
    { path: 'live-session/:course_id/:session_id', component: LiveSession },

    { path: 'lecturers', component: Lecturers },
    { path: 'live-meetings/:lecturer_id', component: LiveMeetings },
    { path: 'meeting/payment-status/:id/:sid', component: MeetingPaymentStatus },
    { path: 'selected-meeting/:id', component: MeetingPaymentPage },

    { path: 'pay-now/:course_id', component: PayNow },
    { path: 'payment-status/:id/:sid', component: PaymentStatus },

    { path: 'video/:id', component: Video},
]

export default routes;