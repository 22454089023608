import {useEffect, useState} from "react";
import appService from "../../Helpers/Services";
import {baseUrl} from "../../Helpers/Util";
import LandingCourseHolder from "../../Containers/LandingCourseHolder";

function Courses() {

    const[courses, setCourses] = useState([]);

    function number_formats(x) {
        return parseFloat(x).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    }

    function loadCourses() {
        appService.getCourses('%', '1', true)
            .then(data => {
                setCourses(data);
            }).catch(e => console.log(e))
    }

    useEffect(data => {
        loadCourses();
    },[])

    return (
        <div id="courses">
            <div className="container-fluid py-5">
                <div className="container py-5">
                    {/*<div className="text-center mb-5">*/}
                    {/*    <h5 className="text-primary text-uppercase mb-3" style={{letterSpacing: '5px'}}>Courses</h5>*/}
                    {/*    <h1>Our Courses</h1>*/}
                    {/*</div>*/}
                    <div className="row">

                        {
                            (courses.length) ?
                            courses.map((value, key) => {
                                return(
                                    <div className="col-lg-4 col-md-6 mb-4" key={key}>
                                        <div className="rounded overflow-hidden mb-2">
                                            <img className="img-fluid" src={baseUrl() + value.image_path} alt=""></img>
                                            <div className="bg-secondary p-4">
                                                <div className="d-flex justify-content-between mb-3">
                                                    {/*<small className="m-0">*/}
                                                    {/*    <i className="fa fa-users text-primary mr-2"></i>*/}
                                                    {/*    Students*/}
                                                    {/*</small>*/}
                                                    {/*<small className="m-0">*/}
                                                    {/*    <i className="far fa-clock text-primary mr-2"></i>*/}
                                                    {/*    {value.duration}h*/}
                                                    {/*</small>*/}
                                                </div>
                                                <a className="h5" style={{textDecoration: 'none'}} href={"/course/" + value.id}>{value.name}</a>
                                                {/*<div className="border-top mt-4 pt-4">*/}
                                                {/*    <div className="d-flex justify-content-between">*/}
                                                {/*        <h6 className="m-0" style={{color: '#44425A'}}>*/}
                                                {/*            <i className="fa fa-star text-primary mr-2"></i>*/}
                                                {/*            <i className="fa fa-star text-primary mr-2"></i>*/}
                                                {/*            <i className="fa fa-star text-primary mr-2"></i>*/}
                                                {/*            <i className="fa fa-star text-primary mr-2"></i>*/}
                                                {/*            <i className="fa fa-star text-primary mr-2"></i>*/}
                                                {/*            /!*4.5 <small>(250)</small>*!/*/}
                                                {/*        </h6>*/}
                                                {/*        <h5 className="m-0">{number_formats(value.fee)} LKR</h5>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <LandingCourseHolder />
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Courses;