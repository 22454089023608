import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import appServices from "../../Helpers/Services";
import {baseUrl} from "../../Helpers/Util";
import defaultPreview from "../../images/default_thumb.png";
import CoursePlaceHolder from "../../Containers/CoursePlaceHolder";

function EnrolledCourse() {
    let { course_id, level_id } = useParams();

    const levelName = new URLSearchParams(window.location.search).get('levelName');

    const [course, setCourse] = useState([]);
    const [videos, setVideos] = useState([]);

    const [loading, setLoading] = useState(false);

    function getCourse() {
        setLoading(true);

        appServices.getCourses(course_id)
            .then(data => {
                setCourse(data[0]);

                //get course videos
                getVideos(data[0].id)

                setLoading(false);
            }).catch(e => console.log(e))
    }

    function getVideos() {
        setLoading(true);
        appServices.getVideos('%', course_id, level_id)
            .then(data => {
                setVideos(data);

                setLoading(false);
            }).catch(e => console.log(e))
    }

    useEffect(() => {
        getCourse();
    }, []);

    return (
        <div>
            <div className="row" style={{paddingLeft: '20px'}}>
                {
                    (course.name == undefined) ? <div className="col-sm-12">
                            <p className="placeholder-glow">
                                <span className="placeholder" style={{width: '50%'}}></span>
                            </p>

                            <p className="placeholder-glow">
                                <span className="placeholder" style={{width: '25%'}}></span>
                            </p>
                    </div> :
                        <div className="col-sm-12">
                            <h4>Videos ({course.name + " - " + levelName})</h4>
                            <Link  to={"/course-materials/" + course_id + "/" + level_id+ "?levelName=" + levelName}>
                                Download Course Materials
                            </Link>
                        </div>
                }
            </div>

                <div className="cardBox">
                    {
                        loading ? <CoursePlaceHolder /> :
                            videos.length ?
                                videos.map((value, key) => {
                                    return (
                                        <Link key={key} to={"/video/" + value.id + "?lesson=" + (key + 1)} style={{textDecoration: 'none'}}>
                                            <div className="card">

                                                <div className="row">
                                                    <div className="card-img">
                                                        <img style={{width: '100%'}} src={
                                                            (value.preview_path == null)? defaultPreview :
                                                            baseUrl() + value.preview_path
                                                        } alt="Lost Preview Image"/>
                                                    </div>
                                                    {/*<div className="col-sm-12 col-md-12 col-lg-12">*/}
                                                    {/*    <h4>*/}
                                                    {/*        Lesson {key + 1}*/}
                                                    {/*    </h4>*/}
                                                    {/*</div>*/}
                                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                                        <div className="cardName">
                                                            {(value.name == null)? "Lesson " + (key + 1) : value.name}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Link>
                                    )
                                }) : <h4 className="mt-5">Uploaded videos N/A</h4>
                    }
                </div>
        </div>
    );
}

export default EnrolledCourse;