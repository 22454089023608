import {Link} from "react-router-dom";
import {baseUrl, getUser} from "../../Helpers/Util";
import {useEffect, useState} from "react";
import appServices from "../../Helpers/Services";
import CoursePlaceHolder from "../../Containers/CoursePlaceHolder";

function AllCourses() {

    const [courses, setCourses] = useState([]);
    const [show, setShow] = useState(true);

    function loadCourses() {
        appServices.getCourses('%', '1', true)
            .then(data => {
                setCourses(data);
            }).catch(e => console.log(e))
    }

    useEffect(() => {
        loadCourses();
    }, []);

    return (
        <div>
            {show && <div className="cardBox">
                {
                    (courses.length == 0) ?
                        <CoursePlaceHolder /> :
                        courses.map(value => {
                            return (
                                <>
                                    <Link exact={true} to={"/all-course-levels/" + value.id} style={{textDecoration: 'none'}}>
                                        <div className="card">
                                            <div>
                                                <div className="card-img">
                                                    <img style={{width: '100%'}} src={baseUrl() + value.image_path} alt=""/>
                                                </div>
                                                <div className="cardName">
                                                    {value.name}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </>
                            )
                        })
                }
            </div>}
        </div>
    );
}

export default AllCourses;